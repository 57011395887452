h1{
  color: rgba(218, 165, 33, 1) !important;
}

.background-color{
  /* background-color: #091641; */
  background: linear-gradient(90deg, #091641 60%, #04217e);
}

.trash{
  color:rgba(218, 165, 33, 1);
  padding-left: 12px;
  padding-bottom: 1px;
  font-size: 40px;
  vertical-align: bottom;
}

.folder{
  padding-bottom: 0px;
}
.row-height{
  height:100%;
  padding: none !important;
}

/* .database-container {
  padding: 20px;
} */

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top:20px;
}

.actions {
  display: flex;
  align-items: center;
}

.button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 60px;
}

.border-right{
  border-right: 2px solid rgba(218, 165, 33, 1);
  transform: translateY(-20px);
  height: calc(100% + 40px);
}

.create-button {
  padding: 10px 20px;
  width: auto; /* Make the button's width auto */
  font-size: 16px;
  background-color: rgba(218, 165, 33, 1);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.create-button:hover {
  background-color: #333;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.video-table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid rgba(218, 165, 33, 1);
  border-radius: 8px !important;
}

.video-table th,
.video-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.video-item:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.move-to-folder {
  margin-right: 10px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Modal styles */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.create-folder-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.create-folder-button {
  padding: 10px 20px;
  width: auto; /* Make the button's width auto */
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.create-folder-button:hover {
  background-color: #333;
}

.cancel-button {
  padding: 10px 20px;
  width: auto; /* Make the button's width auto */
  background-color: #ccc;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button:hover {
  background-color: #bbb;
}

/* .border{
  border: 2px solid rgba(218, 165, 33, 1) !important;
  border-radius: 8px;
  height: 70%;
} */

.header{
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgba(218, 165, 33, 1) !important;
  padding: 0px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}
