.pricing-page {
  font-family: Arial, sans-serif;
  background-color: #091641;;
}

.pricing-page h1 {
  text-align: center;
  padding: 50px 20px;
  font-size: 32px;
  margin-bottom: 20px;
}

.pricing-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.toggle-button {
  padding: 10px 20px;
  border: 1px solid rgba(245, 245, 245, 1) !important;
  background-color: rgba(218, 165, 33, 1) !important;
  cursor: pointer;
  font-size: 16px;
}

.subtext-bold{
  color:rgba(218, 165, 33, 1);
  font-weight: bold;
}

.description{
  color: rgba(245, 245, 245, 1);
}

.subtext{
  color: rgba(218, 165, 33, 1);
}

.toggle-button.active {
  background-color: rgba(218, 165, 33, 1) !important;
  font-weight: bold;
}

.toggle-button span {
  color: #091461;
  font-size: 12px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  border: 1px solid rgba(245, 245, 245, 1) !important;
  background-color: transparent !important;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card h3 {
  color: rgba(218, 165, 33, 1);
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

.card p {
  font-size: 16px;
  margin-bottom: 10px;
}

.card .price {
  color: rgba(218, 165, 33, 1);
  font-size: 28px;
  font-weight: bold;
}

.card .card-button {
  background-color: rgba(218, 165, 33, 1);
  color: #091461;
  padding: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}

.card .card-button:hover {
  background-color: rgba(245, 245, 245, 1);
}

.card ul {
  list-style: none;
  padding: 0;
  flex-grow: 1;
}

.card ul li {
  margin-bottom: 10px;
  color: rgba(245, 245, 245, 1);
  display: flex;
  align-items: center;
}

.card ul li svg {
  color: rgba(218, 165, 33, 1);
  margin-right: 10px;
}

.card ul li.not-available {
  text-decoration: line-through;
  color: red;
}

.card p.description {
  min-height: 60px; /* Adjust as needed to make all descriptions the same height */
}

.card .price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .button-wrapper {
  display: flex;
  justify-content: center;
}

.card ul li {
  min-height: 24px; /* Adjust as needed to ensure all list items have the same height */
  display: flex;
  align-items: center;
}
.card ul li::before {
  content: '✔'; /* Add a checkmark before each list item */
  margin-right: 8px;
  color: rgba(218, 165, 33, 1);
}