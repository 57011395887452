.layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    background-color: #091641;
    /* background-color: #fff; */
    border-bottom: 1px solid #dee2e6;
    width: 100%; /* Ensure the navbar spans the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .navbar-links img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .logout-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .logout-button:hover {
    text-decoration: underline;
  }
  
  .layout-content {
    display: flex;
    flex-grow: 1;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f8f9fa;
    border-right: 1px solid #dee2e6;
    padding: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 10px;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 10px 0;
  }
  
  .sidebar ul li a:hover {
    background-color: #e9ecef;
  }
  
  .sidebar ul li a.active {
    background-color: #007bff;
    color: white;
  }
  