.video-detail-container {
  margin: 20px;
}

.video-detail-container h1 {
  margin-bottom: 20px;
  display: inline-block; /* Ensure the title and buttons are on the same line */
  cursor: pointer;
}

.edit-title-input {
  font-size: 24px;
  padding: 5px;
  width: 100%;
  max-width: 200px;
}

.video-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-detail-container video {
  margin-bottom: 20px;
}

.video-detail-container h2 {
  margin-top: 20px;
}

.video-detail-container pre {
  background-color: #f4f4f4;
  padding: 10px;
  max-width: 100%; /* Ensure pre tags do not exceed container width */
  word-wrap: break-word; /* Ensure long lines break inside pre tags */
}

.delete-button, .rename-button {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
  border: 1px solid #ccc;
  background-color: #828282;
  color: white;
  text-align: center;
  width: 150px; /* Set a fixed width for both buttons */
  margin-left: 10px; /* Add some space between the buttons */
  margin-bottom: 10px;
}

.delete-button:hover, .rename-button:hover {
  background-color: #f8f8f8 !important;
  color: black;
}

.switches {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.switch {
  position: relative;
 
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #f8f8f8;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-label {
  margin-left: 10px;
  font-weight: bold;
  color: #333;
  vertical-align: middle;
}

.edit-button, .download-button {
  display: inline-block; /* Change display to inline-block */
  width: auto;
  min-width: 10%;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #828282;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; 
  margin: 5px; /* Space between buttons */
}

.edit-button:hover, .download-button:hover {
  background-color: #f8f8f8 !important;
  color: black;
}

textarea {
  width: 100%;
  max-width: 100%; /* Ensures the textarea doesn't exceed the container width */
  height: 100px;
  background-color: #e0f7fa; /* light cyan */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  word-wrap: break-word; /* Ensure long lines break inside textareas */
}

textarea:focus {
  outline: none;
  border-color: #80deea; /* cyan */
}

.edit-mode {
  background-color: #f9f9f9; /* light grey */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  width: 400px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.confirm-delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button {
  background-color: gray;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}
