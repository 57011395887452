.homepage {
  font-family: Arial, sans-serif;
  background-color: #091641;
  color: rgba(218, 165, 33, 1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav a {
  margin: 0 10px;
  text-decoration: none;
  color: rgba(218, 165, 33, 1);
}

.signup-button {
  padding: 8px 16px;
  background-color: #091641;
  color: rgba(245, 245, 245, 1);
  border-radius: 4px;
  text-decoration: none;
}

.hero-container {
  text-align: center;
  padding: 50px 20px;
  /* background-image:
    linear-gradient(45deg, #f8f8f8 25%, transparent 25%, transparent 75%, #f8f8f8 75%, #f8f8f8),
    linear-gradient(45deg, #f8f8f8 25%, transparent 25%, transparent 75%, #f8f8f8 75%, #f8f8f8); */
  background-size: 130px 130px;
  background-position: 0 0, 65px 65px;
  margin-bottom: 0%;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  flex-direction: column; /* Ensure vertical stacking of children */
}

.main {
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  margin-bottom: 150px;
}

.hero {
  align-items: center;
  height:400px;
  padding-top:50px;
  margin-bottom: 130px;
}

.hero h1 {
  align-items: center;
  font-size: 60px;
  margin-bottom: 0px;
  margin-top: 100px;
}

.hero p {
  font-size: 32px;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgba(218, 165, 33, 1); /* Initial background color */
  color: rgba(245, 245, 245, 1); /* Initial text color */
  border: 1px solid rgba(218, 165, 33, 1); /* Border color matching the background */
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  max-width: 200px; /* Set a maximum width */
  width: auto; /* Ensure width is auto to fit content */
  margin: 0 auto; /* Center the button horizontally */
}

.hero-container .cta-button:hover {
  background-color: rgba(245, 245, 245, 1); /* Change background color to black on hover */
  color: rgba(218, 165, 33, 1); /* Change text color to white on hover for better contrast */
}

.features {
  /* display: grid; */
  /* grid-template-columns: 1fr auto 1fr; */
  display: flex;
  flex-direction: row;
  /* height:100%; */
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 10%;
  margin-right: 10%;
  /* gap:20px; */
  align-items: stretch;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 300px;
  margin: 0 10px;
}

.feature-header {
  display: flex;
  gap: 10px; /* Adds space between icon and heading */
}


.feature-content {
  margin-left: 10px;
  /* text-align: justify; */
}

.feature h2 {
  margin-bottom: 10px;
}

.feature p {
  margin: 0;
  color: rgba(245, 245, 245, 1);
}

.info-icon {
  font-size: 24px;
  display: block;
  margin-bottom: 10px;
}

.pricing {
  text-align: center;
}

.pricing-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background-color: transparent !important;
}

.toggle-button {
  padding: 8px 16px;
  border: 1px solid rgba(218, 165, 33, 1);
  cursor: pointer;
  background-color: transparent !important; 
  color: #091641; 
  margin: 0 0px; /* Add some margin between buttons */
  width: 120px; /* Fixed width for each button */
  text-align: center; /* Center the text inside the button */
  border-radius: 0px;
  transition: none; /* Remove any transition effects */
}

.toggle-button.active {
  background-color: rgba(218, 165, 33, 1) !important;
  color: transparent !important;
}

.toggle-button:hover {
  color: rgba(245, 245, 245, 1) !important; 
  /* background-color: transparent !important; */
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
  margin-left: 5%;
  margin-right: 5%;
}

.card {
  margin: 10px;
  padding: 15px;
  background-color: #091641;
  border: 1px solid rgba(245, 245, 245, 1);
  border-radius: 8px;
  flex: 1 1 20%;
  text-align: left;
  box-sizing: border-box;
}

.date{
  color: rgba(245, 245, 245, 1) !important;
}

.card li {
  color: rgba(245, 245, 245, 1);
}

.card h3 {
  font-size: 26px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.price {
  font-size: 40px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.price span {
  font-size: 16px;
  color: #828282;
  font-weight: normal;
}

.card-button {
  padding: 10px;
  background-color: rgba(218, 165, 33, 1);
  color: #091641;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.card-button:hover {
  background-color: #212529;
}

.footer {
  padding: 20px;
  /* border: 1px solid #ccc; */
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.footer-logo{
  size: 10px;
}

.footer-columns {
  display: flex;
}

.footer-column {
  margin-right: 50px;
}

.footer-column h4 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  text-decoration: none;
  color: rgba(245, 245, 245, 1);
}

.card ul {
  list-style: none;
  padding: 0;
  flex-grow: 1;
}

.card ul li {
  margin-bottom: 10px;
  color: rgba(245, 245, 245, 1);
  display: flex;
  align-items: center;
}

.card ul li svg {
  color: rgba(218, 165, 33, 1);
  margin-right: 10px;
}

.card ul li.not-available {
  text-decoration: line-through;
  color: red;
}

.card p.description {
  min-height: 60px; /* Adjust as needed to make all descriptions the same height */
}

.card .price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .button-wrapper {
  display: flex;
  justify-content: center;
}

.card ul li {
  min-height: 24px; /* Adjust as needed to ensure all list items have the same height */
  display: flex;
  align-items: center;
}
