body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(180deg, #091641 60%, #04217e);
    color: white;
  }
  
  .billing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .billing-header {
    font-size: 45px;
    margin-bottom: 30px;
    color:white !important;
  }

  .billing-subtitle{
    color:white; 
    padding-left: 2px;
  }

  .content-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }

  .payment-container {
    background-color: rgba(218, 165, 33, 1); 
    padding: 10px;
    border-radius: 8px; 
    display: inline-block; 
  }
  
  .payment-box {
    background-color: rgba(218, 165, 33, 1); 
    border: 4px solid #091641; 
    border-radius: 8px;
    margin: 20px;
    width: 500px;
    height: 350px;
    padding: 30px;
    color: #091641;
  }
  
  .payment-title {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
  }
  
  .divider {
    height: 3px;
    background-color: #091641 !important; 
    margin-top: -2px;
  }
  
  .payment-info {
    font-size: 22px;
    color: #091641;
    margin-bottom: 10px;
  }
  
  .credit-balance {
    font-size: 22px;
    color: #091641;
    /* font-weight: bold; */
  }
  
  .credit-balance span {
    color: #091641; 
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 100px;
  }
  
  .btn {
    background-color: #091641 !important;
    width: 200px;
    color: white !important;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0a1955; 
    transform: scale(1.05);
  }
  

  .billing-options {
    font-size: 1rem;
    color: #f0f0f0;
  }
  
  .billing-options a {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
  
  .billing-options a i {
    color: rgba(218, 165, 33, 1);
    font-size: 28px;
    width: 28px;
  }

  .dollar-shift{
    padding-left: 12px;
  }

  .clock-shift{
    padding-left: 6px;
  }
  
  .billing-options a:hover {
    transform: scale(1.05);
  }

  .secondary-padding{
    padding-left: 10px;
  }

  .breadcrumb{
    text-decoration: none !important;
    color: white;
    align-items: center;
    gap: 3px;
    font-size: 12px !important;
  }

  .breadcrumb:hover{
    color:rgba(218, 165, 33, 1);
  }
