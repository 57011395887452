/* BillingNav.css */
.billing-nav {
    display: flex;
    gap: 20px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .billing-nav a {
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
  }
  
  .billing-nav a.active {
    border-bottom: 2px solid #000;
    font-weight: bold;
    color: #000;
  }
  