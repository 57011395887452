.signup-container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;
}

.signup-container h1 {
  color: #091641;
  text-align: center;
  margin-bottom: 20px;
}

.signup-container form {
  display: flex;
  flex-direction: column;
}

.signup-container .form-group {
  margin-bottom: 15px;
}

.signup-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #091641;
}

.signup-container .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
}

.signup-container .form-group small {
  font-size: 12px;
  color: #091641;
}

.signup-container .error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.signup-container button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #DAA521;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.signup-container button:hover {
  background-color: #DAA521;
}

.signup-container p {
  text-align: center;
  margin-top: 20px;
}

.signup-container p a {
  color: #DAA521;
  text-decoration: none;
}

.signup-container p a:hover {
  text-decoration: underline;
}

.signup-container .checkbox-group {
  display: flex;
  align-items: center;
}

.signup-container .checkbox-group input[type="checkbox"] {
  margin-right: 10px;
}

.signup-container .checkbox-group label {
  margin: 0;
  color: #091641;
}

.login{
  background-color: #091641;
}