.folder-detail-container {
  padding: 20px;
}

.folder-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.folder-actions {
  display: flex;
  gap: 10px;
}

.delete-folder-button{
  width: auto;
  background-color:red;
  color: white;
  margin-left: 5px;
  border-radius: 5px;
}
.rename-folder-button,
.delete-videos-button,
.remove-videos-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: auto;
  margin-right: 5px;
}

.delete-folder-button:hover {
  background-color: #e74c3c;
}

.rename-folder-button:hover,
.delete-videos-button:hover,
.remove-videos-button:hover {
  background-color: #333;
}

.video-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.video-table th, .video-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.video-table th {
  background-color: #f2f2f2;
}

.video-item:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal h2 {
  margin: 0 0 10px;
  font-size: 20px;
}

.Modal button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #555;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.Modal button:hover {
  background-color: #333;
}

.Modal button + button {
  margin-top: 10px;
  background-color: #777;
}

.Modal button + button:hover {
  background-color: #555;
}

.create-folder-input,
.rename-folder-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.cancel-button {
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button:hover {
  background-color: #bbb;
}
