.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #091641;
}

.form-box {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 15px #091641;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.form-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #091641;
}

.input-field {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
}

.input-field label {
  display: block;
  margin-bottom: 5px;
  color: #091641;
  font-weight: bold;
}

.input-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

.button-group {
  width: 100%;
  text-align: right;
}

.button-primary {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #DAA521;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-primary:hover {
  background-color: #DAA521;
}

.text-link {
  color: #DAA521;
  text-decoration: none;
  margin-top: 10px;
}

.text-link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  margin-top: 10px;
} 



