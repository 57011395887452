.color{
  background-color: #091641;
  padding-left:0px !important;
  text-decoration: none !important;
}

.center{
  text-align: center;
}

h2:hover{
  text-decoration: none !important;
}

.create-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.create-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group .hint {
  color: #aaa;
  font-weight: normal;
}

.form-group input[type="file"],
.form-group select,
.form-group input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
}

.form-group input[type="text"]::placeholder {
  color: #aaa;
}

button {
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(218, 165, 33, 1);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #091641;
  color: rgba(218, 165, 33, 1);
}

.result-section {
  margin-top: 20px;
}

.result-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.result-section pre {
  background-color: #f8f8f8;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.result-section button {
  margin-top: 10px;
  width: auto;
  padding: 5px 10px;
}

.toggle-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.toggle-button {
  background-color: #828282 !important;
  color: #fff !important;
  border: 1px solid #ccc !important;
  border-radius: 0px;
  padding: 10px 20px;
  cursor: pointer;
  width: 25%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggle-button.active {
  background-color: #f8f8f8 !important;
  color: black !important;
}

.toggle-button:hover {
  background-color: #ddd !important;
}

.save-video-button {
  margin-top: 20px;
  width: 100%;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-video-button:hover {
  background-color: #218838;
}
