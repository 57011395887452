.center-header{
  align-items: center;
  text-align: center;
  margin: auto;
}
/* .plans-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.pricing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button {
  width: 100px;
  background-color: transparent !important;
  height:28px;
  border-radius: 15px;
  border-color: transparent !important;
  cursor: pointer;
  color: rgba(218, 165, 33, 1) !important;
  padding: 0 !important;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: rgba(218, 165, 33, 1) !important;
  border-radius: 15px;
  padding-left: 15px;
  border-color: transparent !important;
  color: #091641 !important;
}

.toggle-button:hover{
  /* background-color: transparent !important; */
  color: white !important;
  cursor:grab;
}

.plans-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.plans {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  flex-grow: 1;
}

.select-button{
  margin-top: auto;
  /* justify-content: flex-end; */
}

.plan {
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  height: 450px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px;
  padding: 15px;
  flex: 1 1 20%;
  text-align: left;
  box-sizing: border-box;
  color: white;
}

.plan h3,p{
  color: rgba(218, 165, 33, 1);
}
.plan.selected {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.plan h3 {
  margin-top: 0;
}

.plan .price-wrapper {
  margin: 20px 0;
}

.plan .price {
  font-size: 24px;
  font-weight: bold;
}

.plan ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.plan ul li {
  margin-bottom: 10px;
}

.plan button {
  background-color: rgba(218, 165, 33, 1);
  color: #091641;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plan button:hover {
  background-color: #0056b3;
}

.checkout-form-container {
  flex-basis: 300px;
  height: 450px;
  margin-left: 20px;
  margin-top: 5px;
  border: 1px solid white;
  border-radius: 8px;
  padding: 20px;
  background-color: transparent;
  color:rgba(218, 165, 33, 1)
}

.subscription-summary {
  margin-bottom: 20px;
}

.subscription-summary h4 {
  margin-top: 0;
}

.subscription-summary p{
  color: white;
  margin-top: 15px;
}

.subscription-summary button {
  background-color: #daa521;
  color: #091641;
  margin-top: 65%;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 103%;
}

.subscription-summary button:hover {
  background-color: #091641;
  color:rgba(218, 165, 33, 1);
}

.add-margin{
  margin: 10px 0 0 0;
}

.card ul li svg {
  color: rgba(218, 165, 33, 1) !important;
  margin-right: 10px;
}
