.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  background-color: #091461;
  border-bottom: 2px solid rgba(218, 165, 33, 1) !important;
  width: 50px; 
  height: 50px;
}

.navbar-logo img{
  width: 150px;
  height: 40px;
  font-weight: bold;
  color: rgba(218, 165, 33, 1) !important;
  text-decoration: none;
  line-height: 1;
  margin-left: 20px;
}

.logo{
  text-decoration: none !important;
  margin-left: 10px;
}

.color{
  text-decoration: none;
  color: rgba(218, 165, 33, 1);
  padding-left:20px;
}

.color:hover{
  text-decoration: underline;
}


.navbar-links a,
.navbar-links button {
  margin-right: 5px;
  margin-left: 10px;
  text-decoration: none;
  color: rgba(218, 165, 33, 1);
  font-size: 0.9rem;
  padding: 6px 12px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  /* background-color: #f8f9fa;  */
  background-color: transparent;
}

.navbar-links a:hover,
.navbar-links button:hover {
  background-color: rgba(218, 165, 33, 1);
  color: rgba(245, 245, 245, 1);
}

.navbar-links .signup-button {
  color: rgba(218, 165, 33, 1);
  background-color: transparent;
}

.navbar-links .signup-button:hover {
  background-color: rgba(218, 165, 33, 1);
  color: rgba(245, 245, 245, 1);
}

.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}

.logout-button {
  color: rgba(218, 165, 33, 1);
  background: transparent;
  /* border: 1px solid #dee2e6; */
  border-radius: 5px;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 10px;
  /* background-color: #e2e6ea !important;  maybe I'll put this back in not sure*/ 
}

.logout-button:hover {
  background-color: rgba(218, 165, 33, 1);
  color: rgba(245, 245, 245, 1);
}

.welcome-user {
  display: flex;
  color:rgba(218, 165, 33, 1);
  align-items: center;
  margin-right: 10px;
}

.welcome-user span {
  margin-right: 5px;
}

.dropdown {
  position: relative;
  margin-right: 5px;
}

.dropdown-button {
  width: auto;
  background: none;
  border: none;
  color: rgba(245, 245, 245, 1);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 6px 12px;
  background-color: #091461;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.dropdown-button:hover {
  background-color: rgba(218, 165, 33, 1) !important;
  color: rgba(245, 245, 245, 1) !important; 
}

.dropdown-content {
  border-radius: 5px;
  display: flex;
  padding: 15px 15px;
  flex-direction: column;
  position: absolute;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  min-width: 300px;
  top: 90%; /* Position dropdown below the button */
  left: 0;
  background-color: #091461;
}

.dropdown-content a {
  padding: 20px 20px;
  text-decoration: none;
  color: rgba(245, 245, 245, 1);
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  background-color: #091461;
  padding-top: 10px; 
}

.dropdown-content a:hover {
  background-color: rgba(218, 166, 33, 0.364);
  color: rgba(245, 245, 245, 1);
}

.dropdown-title {
  font-size: 1rem;
  font-weight: bold;
}

.dropdown-description {
  font-size: 0.8rem;
  color: rgba(218, 165, 33, 1);
}
